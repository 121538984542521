import React, { useEffect, useState } from "react";
import Pagination from 'react-bootstrap/Pagination'
import CashShopCartService from "./service/CashShopCartService";
import { Link } from "react-router-dom";
import { getAccount } from "../../../services/Auth";
import ControlPanelGlobalData from "../controlPanel/service/ControlPanelGlobalData";

export const CashShopCart = () => {

    const [accountCash, setAccountCash] = useState(0)

    const {
      handleCartItems,
      handlePage,
      handleRemove,
      handleCalcCartTotal,
      handleBuyCartItems,
      cartList,
      totalPages,
      page,
      totalBalance,
      buyed
    } 
    = CashShopCartService()

    const {getAccountData} = ControlPanelGlobalData()

    useEffect(() => {
      handleCartItems()
      getAccountData()
    },[])

    useEffect(() => {
      handleCalcCartTotal()
    }, [cartList])

    useEffect(() => {
      var result = getAccount().cash - totalBalance
      setAccountCash(result)
    },[totalBalance])

    useEffect(() => {
      getAccountData()
    },[buyed])

    let items = [];
    items.push(<Pagination.Item key={'left'} onClick={handlePage(1)}><span aria-hidden="true">«</span></Pagination.Item>);
    items.push(<Pagination.Item key={'left1'} onClick={handlePage(page - 1)}><span aria-hidden="true">‹</span></Pagination.Item>);
    items.push(
        <Pagination.Item activeLabel={false} key={page} onClick={handlePage(1)} active={true}>
        {page}
        </Pagination.Item>
    );
    if( page !== totalPages && totalPages > 0){
        items.push(
            <Pagination.Item activeLabel={false} key={'total1'} onClick={handlePage(totalPages)} active={false}>
            {totalPages}
            </Pagination.Item>
        );
    }
    items.push(<Pagination.Item key={'rigth'} onClick={handlePage(page + 1)}><span aria-hidden="true">›</span></Pagination.Item>);
    items.push(<Pagination.Item key={'rigth1'} onClick={handlePage(totalPages)}><span aria-hidden="true">»</span></Pagination.Item>);

    return(
    <>
     {/*marketContent-title*/}
      <div className="marketContent-title flex-s-c" style={{position:'relative', left:'10px', bottom:'90px'}}>
          <div className="totalTitle">
            <div className="totalTitle-block">
                Total purchase: <span>{totalBalance}</span>
            </div>
            <span className="line" />
            <div className="totalTitle-block">
                balance after purchase: <span>{accountCash}</span>
            </div>
            <span className="line" />
            <div className="totalTitle-block">
              <i className="icon icon-market-white" /> <Link to="#" onClick={(e) => handleBuyCartItems(e)}
              ><span>checkout</span></Link>
            </div>
        </div>
        <div className="totalTitle-block" style={{position:'relative', left:'150px', bottom:'90px'}}>
           <Pagination size="sm">{items}</Pagination>
        </div>
    </div>
    {/*marketContent-title*/}
     <div style={{position:"relative", top:"-105px"}} className="tabs compVend">
          <div className="tabs-content compVend-content active">
            {cartList.Items.map((item, key) => (
              <div key={key} className="compVend-content-block">
              <div className="compVend-img">
                <img src={"/assets/images/item/" + item.Value.itemId + ".png"} alt=""  style={{width:'44px', heigth:'30px'}}/>
              </div>
              <div className="compVend-item">
                <p>Item:</p>
                {item.Value.name}
              </div>
              <div className="compVend-data">
                <p>Quantity:</p>
                {item.Value.count}
              </div>
              <div className="compVend-preco">
                <p>Price:</p>
                {item.Value.price * item.Value.count} Defiant Cash
              </div>
              <div className="compVend-camven">
                <p>Status:</p>
                <Link to="#" onClick={(e) => handleRemove(e, item.Value.Id)} className="text-danger">Remove</Link>
              </div>
            </div>
            ))}
        </div>
    </div>
    </>  
    )
}