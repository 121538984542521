import React, { useEffect } from "react";
import ControlPanelDonateService from "./service/ControlPanelDonateService";
import ValidateForm from "../../../services/ValidateForm";
import GetCurrentMonetarySimbol from "../../../services/GetCurrentMonetarySimbol";

const ControlPanelDonate = ({submitForm}) => {

    const { 
        handleChange,
        handleCashPackets, 
        handleCashPacketFonts,
        handleBuy,
        values, 
        errors, 
        cashPackets, 
        cashPacketFonts 

    } = ControlPanelDonateService(
        submitForm,
        ValidateForm
    );

    useEffect(() =>{
        handleCashPacketFonts();
    },[])

    useEffect(() => {

        cashPacketFonts.forEach(element => {
            if(element.Id == values.cashPacketFontId)
                 return values.cashPacketMethod = element.methodName;   
        });

        handleCashPackets();


    }, [values.cashPacketFontId])

    return(
		<div className="donation-section">
			<h1 className="donation-title">Support Defiant Rebooted</h1>
			<p className="donation-description">
				Your support is greatly appreciated but entirely optional. We want everyone to enjoy the game without feeling pressured to donate.
			</p>
			<hr />
			<div className="donation-options">
				<h2>Donation Options:</h2>
				<ul>
					<p>
						<a href="https://www.buymeacoffee.com/defiant" target="_blank" rel="noopener noreferrer">
							BuyMeACoffee
						</a>
					</p>
					<p>
						<a href="https://patreon.com/user?u=86188482&utm_medium=unknown&utm_source=join_link&utm_campaign=creatorshare_creator&utm_content=copyLink" target="_blank" rel="noopener noreferrer">
							Patreon
						</a>
					</p>
				</ul>
			</div>
			<div className="special-note">
				<h2>Special Note:</h2>
				<p>
					We're truly grateful for any consideration to support the server. Please remember, it's completely optional. No one should feel compelled to donate.
				</p>
			</div>
			<div className="loyalty-bonus">
				<h2>Loyalty Bonus for Old Defiant Donors:</h2>
				<p>
					If you've supported us on the old Defiant server, we have a special offer for you. Any amount you donate now will be <strong>doubled</strong> up to the amount you donated previously. For example, if you donated $100 before and donate $100 again, you'll receive cash worth $200.
				</p>
			</div>
			
			<div className="post-donation-instructions">
				<h2>After Donation:</h2>
					<p>
							Please <a href="https://discord.com/channels/1204382947181072415/1208403767276339241" target="_blank" rel="noopener noreferrer">make a ticket on our Discord</a> to confirm your donation.
						</p>
			</div>
		</div>

    )
}

export default ControlPanelDonate;