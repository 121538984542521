import React, { useEffect } from "react";
import { NoticeService } from "../notice/service/NoticeService";
import dateFormat, { masks } from "dateformat";
import GetDateLocale from "../../../services/GetDateLocale";
import Slider from "react-slick";
import { Link } from "react-router-dom";


const Home = () => {

    const settings = {
        dots: false,
        speed: 2000,
        slidesToShow: 2,
        slidesToScroll: 6,
        autoplay: true,
        infinite: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
            }
          },
          {
            breakpoint: 740,
            settings: {
              slidesToShow: 1,
            }
          }
        ]
    };

    const {
        handleNotices,
        handleFixedNotice,
        notices,
        fixedNotice
    } = NoticeService();

    useEffect(() => {
        handleNotices(1, 0);
        handleFixedNotice();
    },[])

    return(
        <div>
            <link rel="stylesheet" type="text/css" href="/assets/css/styleadd.css" />
            <section className="landing">
                <video
                    playsInline="playsinline"
                    autoPlay="autoplay"
                    muted="muted"
                    loop="loop"
                    >
                    <source src="https://cdn.discordapp.com/attachments/1205627150481358918/1205629232202518668/Class_Reboot_Update__Black_Desert.mp4?ex=65f4c038&is=65e24b38&hm=047edb26dab5228837ffbece2e01dfa7cd207caf414e763a9bba4d030561461e&" />
                </video>
                <div className="container xl-container">
                    <h3>Class Reboot Update: Play the Way You Want!</h3>
                    <div className="text-container">
                        <p>
                            
 
                        </p>
                    </div>
                </div>
                <div className="container">
                {fixedNotice != null &&
                    <h3 className="toUpper">{fixedNotice.name}</h3>
                }
                <div className="scroll-icon" />
                </div>
                </section>
                <section className="latest-news">
                <div className="container-fluid lg-container">
                    {/* S */}
                    <Slider {...settings}>
                        {notices.Items.map((notice, key) => (
                        <div key={key}  className="news">
                            <Link 
                                to={{pathname:"/Notice/Read",
                                    state : {
                                        notice : notice
                                    }
                                }}
                            >
                                <div className="box_latestnews">
                                    <div className="info_latestnews">
                                        {(() => {
                                            switch (notice.type) {
                                            case 1:   return <span className="cate_g type_cate4">News</span>;
                                            case 2: return <span className="cate_g type_cate4">Events</span>;
                                            case 3:  return <span className="cate_g type_cate4">Updates</span>;
                                            }
                                        })()}
                                        <span className="date_g">{dateFormat(notice.createdAt,"dd/mm/yyyy")}</span>
                                    </div>
                                    <p className="desc_latestnews">
                                        {notice.name}
                                    </p>
                                    </div>
                                    <picture style={{position:"relative", right:"-80px"}}>
                                    <img
                                        src={notice.imageIcon}
                                        width={300}
                                        height={200}
                                        alt=""
                                    />
                                </picture>
                            </Link>
                        </div>
                        ))}
                    </Slider>
                </div>
            </section>
                <section className="intro">
                <div className="video-container">
                    <video
                        playsInline="playsinline"
                        autoPlay="autoplay"
                        muted="muted"
                        loop="loop"
                        >
                        <source src="https://cdn.discordapp.com/attachments/1205627150481358918/1205628155084144671/Eternal_Winter_Teaser__Black_Desert.mp4?ex=65f4bf37&is=65e24a37&hm=cc1fb5c914e116e30769c41a0fe2bcbf53956250922d771f7ea2b0de5de7e92d&" />
                    </video>
                </div>
                <div className="container xl-container">
                    <h3>Eternal Winter Expansion</h3>
                    <div className="text-container">
                        <p>
                            "O warrior who shall rise above the six witch trials to stand atop the frosty peaks, come to the Haven of Slumbering Origin at the Mountain of Eternal Winter…"
                        </p>
                    </div>
                </div>
                </section>
        </div>
    )
}

export default Home;