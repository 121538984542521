import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { updateAccountData } from "../../../services/Auth";
import { getAccount } from "../../../services/Auth";

const ControlPanelMain = ({children}) => {

    const location = useLocation();

    useEffect(() => {
        setInterval(() => {
            updateAccountData();
        }, 300000)
    })

    return(
        <div>
            <div className="page-layout">
                <div className="breadcrumbs">
                    <h1>CONTROL PANEL</h1>
                </div>
            </div>
            <section className="dashboard">
                <div className="container lg-container account-panel">
                    <div className="account-menu">
                        <ul className="p-0 m-0">

                        <li className="switch-tab active" 
                            className={location.pathname.match('/ControlPanel/AccountDetails')
                            ? 'switch-tab active' : 'switch-tab'}
                            >
                            <Link to="/ControlPanel/AccountDetails">Account Information</Link>
                        </li>

                        <li className="switch-tab"
                            className={location.pathname.match('/ControlPanel/ChangePassword')
                            ? 'switch-tab active' : 'switch-tab'}
                            >
                            <Link to="/ControlPanel/ChangePassword">Change Password</Link>
                        </li>
                        <li 
                            className={location.pathname.match('/ControlPanel/Donate')
                            ? 'switch-tab active' : 'switch-tab'}
                            >
                            <Link to="/ControlPanel/Donate">Donate</Link>
                        </li>
                        {/*<li 
                            className={location.pathname.match('/ControlPanel/Transactions')
                            ? 'switch-tab active' : 'switch-tab'}
                            >
                            <Link to="/ControlPanel/Transactions">Donation History</Link>
                        </li> */}
                        <li 
                            className={location.pathname.match('/ControlPanel/GiftCode')
                            ? 'switch-tab active' : 'switch-tab'}
                            >
                            <Link to="/ControlPanel/GiftCode">Enter Coupon Code</Link>
                        </li>
                        {/*<li 
                            className={location.pathname.match('/ControlPanel/CashIndications')
                            ? 'switch-tab active' : 'switch-tab'}
                            >
                            <Link to="/ControlPanel/CashIndications">Donations Balance - Referrer</Link>
                        </li> */}
                        {getAccount().privilegie > 0 && 
                        <li 
                            className={location.pathname.match('/ControlPanel/News')
                            ? 'switch-tab active' : 'switch-tab'}
                            >
                            <Link to="/ControlPanel/News">News</Link>
                        </li>
                        }
                        {getAccount().privilegie > 0 && 
                        <li 
                            className={location.pathname.match('/ControlPanel/CashAccountUpdate')
                            ? 'switch-tab active' : 'switch-tab'}
                            >
                            <Link to="/ControlPanel/CashAccountUpdate">Update Cash Account</Link>
                        </li>
                        }
                        </ul>
                    </div>
                    <div className="account-content">
                        <div className="tab active">
                            {children}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ControlPanelMain;