import React, { useState } from "react";
import { Link } from "react-router-dom";
import Signin from "../pages/auth/login/Signin";
import { RecoverAccount } from "../pages/auth/login/RecoverAccount";
import { getToken, getAccount, logout } from "../../services/Auth";

const Header = () => {

    const [showSignin, setShowSigninModal] = useState(false);
    const [showRecoverAccount, setShowRecoverAccount] = useState(false);

    const toggleSignin = () => {
        setShowSigninModal(!showSignin);
    }

    const toggleRecoverAccount = () => {
        setShowRecoverAccount(!showRecoverAccount);
    }

    const handleLogoff = async e => {
        logout();
        window.location.href = "/";
    }

    return(
    <header>
        <div className="container xl-container">
            <div className="section-menu">
                <nav className="navbar navbar-expand-lg navbar-dark">
                    <a className="navbar-brand" href="/">
                    <img src="/assets/images/logo.png" alt="" className="img-fluid" />
                    </a>
                    <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarContent"
                    aria-controls="navbarContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    >
                    <span className="navbar-toggler-icon" />
                    </button>
                    <div className="collapse navbar-collapse" id="navbarContent">
                    <ul className="navbar-nav ml-5 mr-auto">
                        <li className="nav-item">
                            <a className="nav-link" href="/">
                            Home
                            </a>
                        </li>
                        {/* <li className="nav-item dropdown">
                            <a
                                className="nav-link dropdown-toggle"
                                href="#"
                                id="navbarDropdown"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                >
                            Dropdown
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <a className="dropdown-item" href="#">
                                Action
                                </a>
                                <a className="dropdown-item" href="#">
                                Another action
                                </a>
                                <a className="dropdown-item" href="#">
                                Something else here
                                </a>
                            </div>
                        </li> */}
                        <li className="nav-item">
                            <a className="nav-link" href="https://wiki.defiantbd.cc/about/welcome" target="_blank">Wiki</a>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/Register">Register</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/Download">Download</Link>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="https://discord.gg/nUjewqMQY7" target="_blank">Discord</a>
                        </li>
                        {getToken() !== null ?
                        <li className="nav-item">
                            <a className="nav-link" href="/Shop">Shop</a>
                        </li>
                        :
                        <></>
                        }
                        
                    </ul>
                    <div className="account-panel">
                        <ul>
                            {getToken() === null ?
                                <div>
                                    <li className="nav-item dropdown">
                                        <a
                                            className="nav-link dropdown-toggle"
                                            href="#"
                                            id="navbarDropdown"
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            >
                                        Account Panel
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                            
                                            <a href="#" className="dropdown-item" onClick={toggleSignin}>
                                                LOGIN
                                            </a>
                                            <Signin 
                                                isOpen={showSignin}
                                                toggle={toggleSignin}
                                            />

                                            <a href="#" className="dropdown-item" onClick={toggleRecoverAccount}>
                                                RESTORE ACCOUNT
                                            </a>
                                            <RecoverAccount 
                                                isOpen={showRecoverAccount}
                                                toggle={toggleRecoverAccount}
                                            />


                                        </div>
                                    </li>
                                </div>
                                :
                                <li className="nav-item dropdown">
                                    <a
                                        className="nav-link dropdown-toggle"
                                        href="#"
                                        id="navbarDropdown"
                                        role="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        >
                                    Hey, {getAccount().username}
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        
                                        <Link className="dropdown-item" to="/ControlPanel/AccountDetails">Control Panel</Link>

                                        <a className="dropdown-item" href="#" onClick={handleLogoff}>Logoff</a>

                                    </div>
                                </li>
                            }
                        </ul>
                    </div>
                    </div>
                </nav>
            </div>
        </div>
    </header>
    )
}

export default Header;