import React from "react";

const Footer = () => {
    return(
    <div>
        <footer>
            <div className="container xl-container">
                <div className="row">
                    <div className="col-md-6 text-left">
                    </div>
                    <div className="col-md-6 text-right">
                        <img src="/assets/images/logo.png" className="img-fluid" alt="logo" />
                    </div>
                </div>
                <div className="copyright">
                    <p>
                        Copyright 2024 © DefiantBD
                    </p>
                </div>
                    </div>
    </footer>
    </div>
    )
}

export default Footer;