import React, { useEffect } from "react";

export const Download = () => {

    useEffect(() => {
        document.body.className = "page";
    },[])

    return(
        <div>
            <div className="page-layout">
                <div className="breadcrumbs">
                <h1>Downloads</h1>
                </div>
            </div>
            <section className="downloads">
                <div className="container">
                <h2 className="dTitle">
                    <span>Client</span>
                </h2>
                <div className="row downloadOptions">
                    <div className="col-md-6 ml-auto mr-auto">
                    <h3>Full Game Download</h3>
                    <p>Client Size (55GB)</p>
                    <div className="links">
                        <a target="_blank" href="https://nx42738.your-storageshare.de/s/acsCtK2fBKYsQq4/download" className="dLinks dropbox">
                        <img style={{width:"46px"}} src="https://freeiconshop.com/wp-content/uploads/edd/download-cloud-flat.png" alt />
                        <p>Direct DL</p>
                        </a>
                    </div>
                    </div>
                        <div className="col-md-6">
                            <h3>Launcher Download (Recommended)</h3>
                            <p>Launcher Size (16.2MB)</p>
                            <div className="links">
                                <a target="_blank" href="https://launcherassets.defiantbd.cc/Defiant%20Launcher.rar" className="dLinks">
                                    <img style={{width:"46px"}} src="https://freeiconshop.com/wp-content/uploads/edd/download-cloud-flat.png" alt />
                                    <p>Direct Download</p>
                                </a>
                            </div>
                        </div>
                    <div className="col-md-12">
                    <h3>Required programs to run the game</h3>
                    <br></br>
                    <a href="https://go.microsoft.com/fwlink/?LinkId=863262" className="dLinks dropbox">
                        <img style={{width:"90px"}} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQioBD3uFCcapU7mwqLmALOKvOlNexuBuaBCx4XtRuGPP-D01VBmO0XhOFqA9iQYOsVBCs&usqp=CAU" alt />
                        <p>NET Framework 4.7</p>
                    </a>
					<a href="https://media.computerbase.de/s/Tb_SMHmlY0nIDSvvZFha4w/1708195142/download/758/aio-runtimes_v2.5.0.exe" className="dLinks dropbox">
                        <img style={{width:"90px"}} src="https://pics.computerbase.de/2/6/0/8/2/logo-96.f1b1ee21.png" alt />
                        <p>All in One Runtimes</p>
                    </a>
					
                    </div>
                </div>
                </div>
            </section>
        </div>
    )
}